<template>
    <v-container grid-list-xl fluid>
        <h1>Solicitação de cadastro no condomínio</h1>
        <v-divider class="mb-4" />
        <div v-if="$store.getters.isLogged">
            <v-card dark color="secondary" v-if="error == false && structure && status == null" class="pa-4">
                <v-card-title>
                    <v-icon large left> login </v-icon>
                    <span class="text-h6 font-weight-bold">Leia com atenção:</span>
                </v-card-title>
                <v-card-text>
                    Você precisa selecionar qual o bloco e apartamento e clicar para enviar as informações. A solicitação será encaminhada diretamente ao responsável, então basta
                    aguardar a aprovação.
                </v-card-text>

                <v-divider class="my-4 info" style="opacity: 0.22" />

                <v-layout wrap>
                    <v-flex xs12 md6>
                        <v-select v-if="structure.length > 1" :rules="[rules.required]" :items="structure" item-value="name" item-text="name" label="Bloco" class="ma-2"
                            @change="changeAp" return-object />
                        <v-text-field v-else v-model="item.block" label="Bloco" outlined readonly />
                    </v-flex>

                    <v-flex xs12 md6>
                        <v-select v-if="apartments && apartments.length > 0" v-model="item.apartment" :rules="[rules.required]" :items="apartments" item-value="id" item-text="name"
                            label="Apartamento" class="ma-2" />
                        <v-text-field v-else v-model="item.apartment" label="Apartamento"
                            :rules="[rules.required, (v) => v <= 10000 || 'Máximo 10000', (v) => v >= 1 || 'Mínimo 1']" type="number" required outlined />
                    </v-flex>
                </v-layout>

                <v-divider class="my-4 info" style="opacity: 0.22" />

                <v-card-actions class="justify-center"> <v-btn color="success" :disabled="block" style="width: 100%" @click="send"> Enviar solicitação </v-btn></v-card-actions>
            </v-card>

            <v-alert text color="error" v-if="error">
                <h3 class="text-h5 font-weight-bold">Problema ao identificar condomínio</h3>
                <div class="mb-12">Entre em contato para reportar o problema clicando abaixo.</div>
                <v-btn style="width: 100%" tile color="grey" dark :href="errorHref" target="_blank"> <v-icon left> fab fa-whatsapp </v-icon> Reportar Problema </v-btn>
            </v-alert>

            <v-alert text color="warning" v-if="status != null">
                <h3 class="text-h5 font-weight-bold" v-if="status == 'PENDING'">Seu cadastro está em avaliação.</h3>
                <h3 class="text-h5 font-weight-bold" v-else-if="status == 'OK'">Seu cadastro está pronto para uso nesse condominio.</h3>
                <h3 class="text-h5 font-weight-bold" v-else>Seu cadastro está com algum problema, entre em contato com o responsável pelo prédio.</h3>
                <div class="mb-12">Caso queira reportar algum problema clique abaixo para ajudarmos.</div>
                <v-btn style="width: 100%" tile color="grey" dark :href="duplicateHref" target="_blank"> <v-icon left> fab fa-whatsapp </v-icon> Reportar Problema </v-btn>
            </v-alert>
        </div>
        <div v-else>
            <v-alert text color="error" icon="app_blocking">
                <h3 class="text-h5 font-weight-bold">Funcionalidade apenas para usuários cadastrados, faça login ou cadastre-se para prosseguirmos!!!</h3>
            </v-alert>
        </div>
    </v-container>
</template>

<script>
import rules from "@/helpers/rules";

export default {

    mounted() {
        if (this.$store.getters.isLogged) {
            if (this.$route.params.code) {
                this.link = this.$route.params.code;
                this.check();
            } else {
                this.error = true;
            }
        }
    },

    data() {
        return {
            rules,
            link: "",
            error: false,
            structure: null,
            apartments: null,
            status: null,
            block: false,
            item: {
                id: null,
                block: null,
                apartment: null,
            },
        };
    },

    computed: {
        errorHref() {
            return encodeURI(`https://wa.me/5511973778065?text=Erro ao solicitar cadastro para o condomínio ${this.link}`);
        },
        problemHref() {
            return encodeURI(`https://wa.me/5511973778065?text=Estou com problema no meu cadastro do condomínio ${this.link}`);
        },
    },

    methods: {
        changeAp(newSelected) {
            this.item.block = newSelected.name;
            if (this.structure && this.item.block) {
                this.apartments = newSelected.apartments;
            } else {
                this.apartments = null;
            }
        },
        send() {
            this.block = true;
            this.$http
                .post("api/v2/mycondominiuns", this.item)
                .then((result) => {
                    this.block = false;
                    this.$eventHub.$emit("msgSuccess", result.message ? result.message : "Enviado com sucesso.");
                    this.check();
                })
                .catch((error) => {
                    this.block = false;
                    this.error = true;
                    this.$eventHub.$emit("msgError", error.message ? error.message : "Erro ao enviar, tente novamente.");
                });
        },
        check() {
            this.$http
                .post("api/v2/checkresident", { link: this.link })
                .then((result) => {
                    if (result) {
                        this.structure = result.structure;
                        this.status = result.status;
                        this.item.id = result.id;
                        if (!this.structure || this.structure.length == 0) {
                            this.error = true;
                        } else if (this.structure.length == 1) {
                            this.item.block = this.structure[0].name;
                            this.apartments = this.structure[0].apartments;
                        }
                    }
                })
                .catch(() => {
                    this.error = true;
                });
        },
    },
};
</script>
